export function scaleToImage(mm: number[], px: number): number[] {
  const maxBrickWidthMm = Math.max(...mm)
  return mm.map((mm) => Math.round((mm / maxBrickWidthMm) * px))
}

export function scaleToImageObject(mm: number[], px: number): Record<number, number> {
  return mm.reduce((acc: Record<number, number>, mm) => {
    acc[mm] = mm * 2.5
    return acc
  }, {})
}

export function mmToPixels(mm: number, dpi = 300): number {
  const inches = mm / 0.254
  const pixels = inches * dpi
  return pixels
}

export function findIntersection(arrays?: number[][]): number[] {
  if (!arrays) return []
  if (arrays.length === 0) return []

  let intersection = arrays[0]

  for (let i = 1; i < arrays.length; i++) {
    intersection = intersection.filter((value) => arrays[i].includes(value))
  }

  return intersection
}

export function ensureNestedArray<T>(collection: Record<string, any>, ...keys: string[]): T[] {
  return keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      if (!Array.isArray(acc[key])) {
        acc[key] = []
      }
    } else {
      if (!acc[key]) {
        acc[key] = {}
      }
    }
    return acc[key]
  }, collection) as T[]
}

export function uuid() {
  const randomHex = () => Math.floor(Math.random() * 16).toString(16)

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const random = randomHex()
    if (char === 'x') {
      return random
    } else {
      const randomY = (parseInt(random, 16) & 0x3) | 0x8
      return randomY.toString(16)
    }
  })

  return uuid
}
