export const METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
  options: 'OPTIONS'
} as const

export type Method = (typeof METHODS)[keyof typeof METHODS]

interface RequestTarget {
  url: string
  method: Method
}

export interface RequestBody {
  body: any
  params: any
}

type RequestInitWithoutBody = Omit<RequestInit, 'body' | 'params' | 'method'>

export interface Config extends RequestInitWithoutBody, Partial<RequestTarget>, Partial<RequestBody>, Partial<Interceptor> {
  baseURL?: string
  headers?: Record<string, string>
  options?: Record<string, any>
  timeout?: number
  responseType?: 'json' | 'blob' | 'arraybuffer' | 'text' | 'formData'
}

export type FetchParams = string | string[][] | Record<string, string> | URLSearchParams

interface Interceptor
  extends Record<
    'interceptors',
    {
      request: RequestInterceptor[]
      response: ResponseInterceptor[]
      error: ErrorInterceptor[]
      timeout: TimeoutInterceptor[]
    }
  > {}

interface RequestInterceptor {
  (requestConfig: Partial<Config>): Partial<Config> | Promise<Partial<Config>>
}

interface ResponseInterceptor {
  (response: Response): Response | Promise<Response>
}

interface ErrorInterceptor {
  (error: any): any | Promise<any>
}

interface TimeoutInterceptor {
  (timeout: number): void
}

interface Instance {
  instanciate: (options: Partial<Config>) => {
    create: <T>(method: Method, uri: string, ...requestConfig: Config[]) => Promise<T>
    interceptors: {
      timeout: (interceptor: TimeoutInterceptor, errorInterceptor?: ErrorInterceptor) => void
      request: (interceptor: RequestInterceptor, errorInterceptor?: ErrorInterceptor) => void
      response: (interceptor: ResponseInterceptor, errorInterceptor?: ErrorInterceptor) => void
    }
    defaults: Config
  }
}

const defaultHeaders: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}

const requestTarget: RequestTarget = {
  url: '',
  method: 'GET'
}

const interceptor: Interceptor = {
  interceptors: {
    request: [],
    response: [],
    error: [],
    timeout: []
  }
}

const init: Config = {
  baseURL: '',
  headers: defaultHeaders,
  ...interceptor
}

function clear(...a: unknown[][]) {
  a.forEach((element) => {
    if (element) {
      element.length = 0 // Clears the array efficiently
    }
  })
}

function timeout(interceptor: TimeoutInterceptor, error?: ErrorInterceptor) {
  init?.interceptors?.timeout?.push(interceptor)
  if (error) {
    init?.interceptors?.error?.push(error)
  }
}

function request(interceptor: RequestInterceptor, error?: ErrorInterceptor) {
  init?.interceptors?.request?.push(interceptor)
  if (error) {
    init?.interceptors?.error?.push(error)
  }
}

function response(interceptor: ResponseInterceptor, error?: ErrorInterceptor) {
  init?.interceptors?.response?.push(interceptor)
  if (error) {
    init?.interceptors?.error?.push(error)
  }
}

type InterceptorsFunctionsMap = {
  request: (value: Config) => Promise<void>
  response: (response: Response) => Promise<void>
  error: (error: any) => Promise<void>
  timeout: (timeout: number) => Promise<void>
}

const interceptorsMap: InterceptorsFunctionsMap = {
  request: async (value: Config): Promise<void> => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.request) {
      await interceptor(value)
    }
  },
  response: async (response: Response): Promise<void> => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.response) {
      await interceptor(response)
    }
  },
  error: async (error: any): Promise<void> => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.error) {
      await interceptor(error)
    }
  },
  timeout: async (timeout: number): Promise<void> => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.timeout) {
      interceptor(timeout)
    }
  }
}

function timeoutPromise(ms: number, controller: AbortController): Promise<never> {
  return new Promise((_, reject) => {
    const timer = setTimeout(() => {
      if (!controller.signal.aborted) {
        controller.abort()
        interceptorsMap['timeout'](ms)
        reject(new Error('Request timed out'))
      }
    }, ms)

    controller.signal.addEventListener('abort', () => clearTimeout(timer))
  })
}

async function doRequest<T>(requestConfig: Config): Promise<T> {
  const { body, ...restRequestConfig } = requestConfig
  const config = {
    ...init,
    ...requestTarget,
    ...restRequestConfig,

    body: body
  }

  const { url, timeout = 5000, responseType = 'json' } = config

  const controller = new AbortController()
  const signal = controller.signal
  config.signal = signal

  if (requestConfig.signal) {
    config.signal = requestConfig.signal
  }

  try {
    await interceptorsMap['request'](config)

    const response = await Promise.race<Response>([fetch(url, config as RequestInit), timeoutPromise(timeout, controller)])

    await interceptorsMap['response'](response)

    if (!response.ok) {
      const errorMessage = response.statusText || 'Unknown error'
      throw new Error(errorMessage)
    }

    if (response.status === 204) {
      return {} as T
    }

    return await processResponse<T>(response, responseType)
  } catch (error) {
    await interceptorsMap['error'](error)
    return Promise.reject(error)
  } finally {
    clear(config.interceptors!.request, config.interceptors!.response, config.interceptors!.error, config.interceptors!.timeout)
  }
}

type ResponseProcessor = (response: Response) => Promise<any>

const responseTypeMap: Record<string, ResponseProcessor> = {
  blob: (response) => response.blob(),
  arraybuffer: (response) => response.arrayBuffer(),
  text: (response) => response.text(),
  formData: (response) => response.formData(),
  json: (response) => response.json()
}

async function processResponse<T>(response: Response, responseType: string): Promise<T> {
  const processor = responseTypeMap[responseType] || responseTypeMap['json']
  return (await processor(response)) as T
}

type ParamProcessor = (params: any) => string

const paramProcessorMap: Record<string, ParamProcessor> = {
  string: (params: string) => params,
  object: (params: object) => {
    const searchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, String(value))
      }
    })
    return searchParams.toString()
  },
  array: (params: string[][]) => new URLSearchParams(params).toString(),
  urlsearchparams: (params: URLSearchParams) => params.toString()
}

function processParams(params: any): string {
  if (!params) return ''

  if (typeof params === 'string') {
    return paramProcessorMap['string'](params)
  } else if (params instanceof URLSearchParams) {
    return paramProcessorMap['urlsearchparams'](params)
  } else if (Array.isArray(params)) {
    return paramProcessorMap['array'](params)
  } else if (typeof params === 'object') {
    return paramProcessorMap['object'](params)
  }

  return ''
}

function serializeBody(body: any): string | FormData | URLSearchParams | Blob | ArrayBuffer | null | undefined {
  if (body === undefined || body === null) {
    return body
  }

  if (body instanceof FormData || body instanceof URLSearchParams || body instanceof Blob || body instanceof ArrayBuffer) {
    return body
  }

  if (typeof body === 'object') {
    return JSON.stringify(body)
  }

  return String(body)
}

function mergeHeaders(...headersList: Array<Record<string, string> | undefined>): Record<string, string> {
  const result: Record<string, string> = {}

  for (const headers of headersList) {
    if (headers) {
      Object.assign(result, headers)
    }
  }

  return result
}

function createRequestConfig(method: Method, uri: string, values: Config[]): Config {
  const combinedValues: Config = Object.assign({}, ...values)
  const url = new URL(uri, init.baseURL)

  if (combinedValues?.params) {
    url.search = processParams(combinedValues.params)
  }

  const { body, ...restCombinedValues } = combinedValues

  const newConfig: Config = {
    ...init,
    method,
    url: url.href,
    headers: mergeHeaders(defaultHeaders, init.headers, combinedValues.headers),
    ...restCombinedValues,
    body: serializeBody(body)
  }

  return newConfig
}

export const fetchInstance: Instance = {
  instanciate: (values: Config) => {
    Object.assign(init, values)

    const instanceDefaults: Config = {
      ...init,
      headers: { ...init.headers, ...defaultHeaders }
    }

    return {
      create: async <T>(method: Method, uri: string, ...values: Config[]): Promise<T> => {
        const config = createRequestConfig(method, uri, [{ ...instanceDefaults }, ...values])
        return await doRequest<T>(config)
      },
      interceptors: {
        timeout,
        request,
        response
      },
      defaults: instanceDefaults
    }
  }
}
