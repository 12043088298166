<script setup lang="ts">
import { useThrottleFn } from '@/services/trottle'

const emits = defineEmits<{ (e: 'render'): void; (e: 'export'): void }>()

const trottle = useThrottleFn(() => emits('render'), 1000)
</script>

<template>
  <nav class="menu">
    <ul>
      <li>
        <a href="javascript:void(0)" @click.prevent="trottle">
          Stenen herschikken
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17.958">
            <path
              fill="#292524"
              d="m11.76 11.742 1.126 1.456a2 2 0 0 0 1.589.776l2.119-.008-.301-.3a1 1 0 0 1-.083-1.32l.083-.095a1 1 0 0 1 1.414 0l2 2 .027.028a1.004 1.004 0 0 1 .266.675v.033l-.004.06.004-.093a1.008 1.008 0 0 1-.255.672 1.174 1.174 0 0 1-.038.04l-2 2a1 1 0 0 1-1.414-1.415l.283-.285-2.095.008a4 4 0 0 1-3.177-1.552l-1.126-1.456a1 1 0 0 1 1.582-1.224ZM17.707.292l2 2c.03.031.056.06.08.09l-.08-.09a1.008 1.008 0 0 1 .293.712v.028l-.005.063.005-.091a1.009 1.009 0 0 1-.266.675.875.875 0 0 1-.027.028l-2 2a1 1 0 0 1-1.414-1.414l.301-.303-1.985-.006a2 2 0 0 0-1.672.89l-6.21 9.315A4.002 4.002 0 0 1 3.38 15.97l-2.384-.012a1 1 0 0 1 .01-2l2.384.012a2.002 2.002 0 0 0 1.675-.89l6.209-9.314a4 4 0 0 1 3.343-1.782l1.959.006-.282-.283a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.414 0ZM3.45 1.988a4 4 0 0 1 3.265 1.66l.99 1.372a1 1 0 0 1-1.623 1.17l-.989-1.372a2 2 0 0 0-1.633-.83L1.005 4a1 1 0 0 1-.01-2l2.455-.013Z"
            />
          </svg>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click.prevent="$emit('export')">
          Download texturen
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path
              fill="#292524"
              d="M17 12.444a1 1 0 0 1 1 1A4.556 4.556 0 0 1 13.444 18H4.556A4.556 4.556 0 0 1 0 13.444a1 1 0 0 1 2 0A2.556 2.556 0 0 0 4.556 16h8.888A2.556 2.556 0 0 0 16 13.444a1 1 0 0 1 1-1ZM9 0a1 1 0 0 1 1 1v8.251l1.849-1.847a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 0 1.414L9.74 12.342a.997.997 0 0 1-1.472.01L4.737 8.817A1 1 0 1 1 6.15 7.404L8 9.253V1a1 1 0 0 1 1-1Z"
            />
          </svg>
        </a>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
