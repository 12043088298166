import { ref } from 'vue'

import { clientSession } from '@/services/api'
import { uuid } from '@/services/uuid'
import { dialog } from '@/components/modal/modal'
import { storedUserValues } from './components/information'

import type { LoadingModalObject } from '@/container/components/loading/Loader.vue'

export interface StatusRepsonse {
  job: JobResponse
  worker?: WorkerResponse
}

interface WorkerResponse {
  id: number
  status: number
  index: number
  message: string
  file: string
}

interface JobResponse {
  id: number
  index: number
  message: string
  status: number
}

interface JobStatusHandler extends Record<string, (status: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => void> {}

interface WorkerStatusHandler extends Record<string, (worker: WorkerResponse, checkStatus: () => void) => void> {}

const pingTimeout = 3000

export const [Loading, showLoadingModal, closeLoadingModal] = dialog(() => import('@/container/components/loading/Modal.vue'))

export const loadingStatus = ref<LoadingModalObject>({
  status: '',
  index: 0
})

function updateLoadingModal(jobOrWorker: JobResponse | WorkerResponse) {
  loadingStatus.value.status = jobOrWorker.message
  loadingStatus.value.index = Number(jobOrWorker.index)
}

// Returns one of these values: 'completed', 'failed', 'delayed', 'active', 'waiting', 'waiting-children', 'unknown'.
export const jobStatusHandlerMap: JobStatusHandler = {
  delayed: ({ job }: { job: JobResponse }, checkStatus: () => void) => {
    updateLoadingModal(job)
    setTimeout(checkStatus, pingTimeout)
  },
  'waiting-children': ({ job }: { job: JobResponse }, checkStatus: () => void) => {
    updateLoadingModal(job)
    setTimeout(checkStatus, pingTimeout)
  },
  unknown: ({ job }: { job: JobResponse }, checkStatus: () => void) => {
    updateLoadingModal(job)
    setTimeout(checkStatus, pingTimeout)
  },
  pending: ({ job, worker }: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => {
    if (!worker) updateLoadingModal(job)
    setTimeout(checkStatus, pingTimeout)
  },
  waiting: ({ job }: { job: JobResponse }, checkStatus: () => void) => {
    updateLoadingModal(job)
    setTimeout(checkStatus, pingTimeout)
  },
  active: ({ job, worker }: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => {
    if (!worker) {
      updateLoadingModal(job)
      setTimeout(checkStatus, pingTimeout)
    } else workerStatusHandlerMap[worker!.status || 'started'](worker!, checkStatus)
  },
  processing: ({ job, worker }: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => {
    if (!worker) {
      updateLoadingModal(job)
      setTimeout(checkStatus, pingTimeout)
    } else workerStatusHandlerMap[worker!.status || 'started'](worker!, checkStatus)
  },
  completed: ({ job, worker }: { job: JobResponse; worker?: WorkerResponse }, checkStatus: () => void) => {
    if (!worker) {
      updateLoadingModal(job)
      setTimeout(checkStatus, pingTimeout)
    } else workerStatusHandlerMap[worker!.status](worker!, checkStatus)
  },
  failed: ({ job }: { job: JobResponse }) => {
    updateLoadingModal(job)
    setTimeout(closeLoadingModal, pingTimeout)
  }
}

const workerStatusHandlerMap: WorkerStatusHandler = {
  started: (worker: WorkerResponse, checkStatus: () => void) => {
    updateLoadingModal(worker)
    setTimeout(checkStatus, pingTimeout)
  },
  processing: (worker: WorkerResponse, checkStatus: () => void) => {
    updateLoadingModal(worker)
    setTimeout(checkStatus, pingTimeout)
  },
  completed: async (worker: WorkerResponse) => {
    updateLoadingModal(worker)
    downloadZipfile(worker)
  },
  success: async (worker: WorkerResponse) => {
    updateLoadingModal(worker)
    downloadZipfile(worker)
  },
  failed: (worker: WorkerResponse) => {
    updateLoadingModal(worker)
    setTimeout(closeLoadingModal, pingTimeout)
  }
}

export async function downloadZipfile(worker: WorkerResponse, mail: boolean = true) {
  try {
    const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}download/${worker.id}`, {
      method: 'POST',
      headers: {
        'X-User': clientSession.value?.id ?? uuid.value
      },
      body: JSON.stringify({
        file: worker.file,
        mail: mail,
        user: storedUserValues
      })
    })

    if (!response.ok) {
      throw new Error('File download failed.')
    }

    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')

    a.href = url
    a.download = worker.file
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
  } catch (error) {
    loadingStatus.value.status = 'Er is een fout opgetreden bij het downloaden van de bestanden. Probeer het later opnieuw. De link kan verlopen zijn.'
    console.error('Error downloading file:', error)
  } finally {
    setTimeout(closeLoadingModal, 300)

    loadingStatus.value.status = ''
    loadingStatus.value.index = 0
  }
}
