<script setup lang="ts">
import { onMounted } from 'vue'

import { ServerTimeoutModal } from './services/api'
import { Loading, loadingStatus } from './container/ping'
import { Information } from './container/components/information'
import { downloadZipfile } from './container/ping'
import { dialog } from '@/components/modal/modal'

import Canvas from '@/container/Canvas.vue'
import Toast from '@/components/toast/Toast.vue'

const [Mail, openMail, closeMail] = dialog(() => import('./container/components/loading/Mail.vue'))

onMounted(async () => {
  const searchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(searchParams)
  if (!params.filename) return

  openMail()

  try {
    await downloadZipfile(
      {
        id: 0,
        status: 0,
        index: 0,
        message: '',
        file: params.filename
      },
      false
    )
    closeMail()
  } catch (error) {
    setTimeout(closeMail, 1500)
  } finally {
    window.location.search = ``
  }
})
</script>

<template>
  <KeepAlive>
    <Suspense>
      <Canvas />
    </Suspense>
  </KeepAlive>
  <Toast />
  <Information />
  <Mail :loading="loadingStatus" :outside="false" />
  <Loading :loading="loadingStatus" :outside="false" />
  <ServerTimeoutModal size="sm" :outside="false" />
</template>
