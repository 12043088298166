import Modal from './Modal.vue'

import { uuid } from '@/services/uuid'
import { outside } from '@/services/outside'
import { createApp, defineAsyncComponent, defineComponent, getCurrentInstance, h, onMounted, ref, shallowRef } from 'vue'

import type { App, AsyncComponentLoader, Component, ComponentPublicInstance, ShallowRef } from 'vue'

import './polyfill'

interface ExtraModelArgs {
  once?: boolean
}

export interface UseModal {
  createInstance: (binding: Component, attrs: ModalProps & Record<string, unknown>) => void
  showModal: <T>(el?: AsyncComponentLoader<new () => T>, props?: Record<string, unknown> & ExtraModelArgs) => void
  closeModal: () => void
}

export interface ModalProps {
  header?: string
  outside?: boolean
  size?: 'sm' | 'md' | 'lg' | 'full'
}

export type DynamicDialog<T> = [Component, (el?: AsyncComponentLoader<new () => T>, props?: Record<string, unknown> & any) => void, () => void]

const modals = shallowRef<App<Element>[]>([])
const dialogContainers = shallowRef<HTMLDialogElement[]>([])
const dialogInstances = shallowRef<ComponentPublicInstance[]>([])
const dialogUuids = shallowRef<string[]>([])

function openDialog(): void {
  const dialogContainer = dialogContainers.value.pop()
  if (dialogContainer && document.body.contains(dialogContainer)) {
    dialogContainer.showModal()
  } else {
    console.error('Dialog container is not in the document.')
  }
}

export function closeDialog(): void {
  const dialogContainer = dialogContainers.value.pop()
  if (dialogContainer) {
    dialogContainer.close()
    setTimeout(() => dialogContainer.remove(), 100)

    modals.value.pop()

    dialogInstances.value.pop()
    dialogUuids.value.pop()
  }
}

function clearModals(): void {
  modals.value.forEach((modal) => modal.unmount())
  dialogContainers.value.forEach((dialog) => dialog.remove())

  modals.value = []

  dialogContainers.value = []
  dialogInstances.value = []
  dialogUuids.value = []
}

export function dialog<T>(el: AsyncComponentLoader<new () => T>): DynamicDialog<T> {
  const component = defineAsyncComponent(el)

  const { showModal, closeModal, createInstance } = useModal()

  const elementUuid = ref<string>('')

  const wrapper = defineComponent({
    setup() {
      const instance = getCurrentInstance()
      const attrs = instance ? instance.attrs : {}

      onMounted(() => {
        elementUuid.value = uuid.value
        createInstance(component, attrs)
      })

      return () => h('div', { id: elementUuid.value })
    }
  })

  return [wrapper, showModal, closeModal]
}

export function useModal(): UseModal {
  const { initModal, createInstance } = setupModal()

  function showModal<T>(el?: AsyncComponentLoader<new () => T>, props?: Record<string, unknown> & ExtraModelArgs): void {
    if (props?.once) {
      closeModal()
      clearModals()
      delete props?.once
    }

    if (el) {
      const component = defineAsyncComponent(el)
      createInstance(component, props || {})
    }

    initModal()
    openDialog()
  }

  function closeModal(): void {
    closeDialog()
  }

  return {
    createInstance,
    showModal,
    closeModal
  }
}

function appendDialog(bindAttrs: ShallowRef<(ModalProps & Record<string, unknown>) | null>): HTMLDialogElement {
  const id = uuid.value

  const dialogContainer = document.createElement('dialog')
  dialogContainer.id = id
  dialogContainer.classList.add('dialog')

  if (bindAttrs.value?.size) {
    dialogContainer.classList.add(`dialog-${bindAttrs.value.size}`)
  }

  document.body.appendChild(dialogContainer)
  dialogContainers.value.push(dialogContainer)
  return dialogContainer
}

function setupModal() {
  const bindContainer = shallowRef<Component | null>(null)
  const bindAttrs = shallowRef<(ModalProps & Record<string, unknown>) | null>(null)

  function initModal(): void {
    const dialogContainer = appendDialog(bindAttrs)

    if (bindAttrs.value?.outside !== false) {
      dialogContainer.addEventListener('click', closeDialog)
    }

    const modalProps: Record<string, unknown> = { ...bindAttrs.value }

    const modal = createApp({
      render: () => h(Modal, modalProps, { default: () => (bindContainer.value ? h(bindContainer.value, modalProps) : null) })
    })

    modal.use(outside)

    modals.value.push(modal)
    dialogInstances.value.push(modal.mount(dialogContainer))
    dialogContainers.value.push(dialogContainer)
  }

  async function createInstance(binding: Component, attrs: ModalProps & Record<string, unknown>): Promise<void> {
    bindContainer.value = binding
    bindAttrs.value = attrs
  }

  return {
    initModal,
    createInstance
  }
}
