import type { Canvas } from '.'
import { ref } from 'vue'

export const example = ref()

export function setExampleImage(data: Canvas, foreground: string, desiredBrickHeightPx = 50) {
  const offscreenCanvas = document.createElement('canvas')

  offscreenCanvas.width = 1920
  offscreenCanvas.height = 1080

  const ctx = offscreenCanvas.getContext('2d')
  if (!ctx) return

  const bgImage = new Image()
  bgImage.src = data.image

  bgImage.onload = () => {
    const scaleCanvas = document.createElement('canvas')
    const estimatedBricksInOriginal = 6
    const originalBrickHeight = data.height / estimatedBricksInOriginal

    const scaleFactor = desiredBrickHeightPx / originalBrickHeight

    const scaledWidth = data.width * scaleFactor
    const scaledHeight = data.height * scaleFactor

    scaleCanvas.width = scaledWidth
    scaleCanvas.height = scaledHeight

    const scaleCtx = scaleCanvas.getContext('2d')
    if (!scaleCtx) return

    scaleCtx.drawImage(bgImage, 0, 0, scaledWidth, scaledHeight)

    const pattern = ctx.createPattern(scaleCanvas, 'repeat')

    if (pattern) {
      ctx.fillStyle = pattern
      ctx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height)
    }

    const fgImage = new Image()
    fgImage.src = foreground

    fgImage.onload = () => {
      ctx.drawImage(fgImage, 0, 0, offscreenCanvas.width, offscreenCanvas.height)
      example.value = offscreenCanvas.toDataURL('image/jpeg')
    }
  }
}
