<script setup lang="ts">
import { shallowRef, type Component } from 'vue'
import { example } from '../example'
import { dialog } from '@/components/modal/modal'

const [Wrapper, showModal, closeModal] = dialog(() => import('@/container/Example.vue'))

interface Options {
  component: Component
  showModal: () => void
  closeModal: () => void
}

const options = shallowRef<Options[]>([
  {
    component: Wrapper,
    showModal,
    closeModal
  }
])
</script>

<template>
  <div class="choose">
    <ul>
      <template v-for="({ component, showModal, closeModal }, index) in options" :key="index">
        <template v-if="example">
          <li>
            <a @click.prevent="showModal()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <path
                  fill="#FFF"
                  d="M8.059 0a8.059 8.059 0 0 1 6.362 13.007l3.286 3.286a1 1 0 0 1-1.414 1.414l-3.287-3.286A8.059 8.059 0 1 1 8.06 0Zm0 2a6.059 6.059 0 1 0 4.284 10.343l.013-.012A6.059 6.059 0 0 0 8.06 2Z"
                />
              </svg>
              <img :src="example" />
            </a>
          </li>
          <component :is="component" v-bind="$attrs" @close="closeModal()" size="full" />
        </template>
      </template>
    </ul>
  </div>
</template>
