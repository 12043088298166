import { computed, onMounted, ref, shallowRef, type ModelRef, type Ref } from 'vue'
import { debounce } from '@/services/debounce'
import { session } from '#main/client/src/services/session.ts'
import { uuid } from '@/services/uuid'
import { BROWSER_SESSION_ID, clientSession } from '#main/client/src/services/api.ts'

import type { CanvasFilter, RulesSet } from '=/types'
import type { Form } from '@/services/form'

export const NEW_STONE_KEY = 'new_stone'

export const choose = ref<boolean>(false)
export const selected = ref<{ name: string; index: number }>({ name: '', index: -1 })

export function closeChoose() {
  choose.value = false
  resetSelection()
}

function resetSelection() {
  selected.value = { name: '', index: -1 }
}

function start() {
  if (!clientSession.value?.first) return

  selected.value = {
    name: NEW_STONE_KEY,
    index: -1
  }

  choose.value = true
}

const percentageDistribution: Record<number, number> = {
  1: 100,
  2: 50,
  3: 33.33,
  4: 25
}

function handleFirstSession(brickData: RulesSet['bricks'][number], bricks: CanvasFilter['bricks']) {
  const { index, name, folder, example } = brickData
  bricks.splice(0, bricks.length, {
    index,
    name,
    folder,
    percentage: percentageDistribution[bricks.length] ?? 0,
    example
  })

  selected.value = {
    name,
    index
  }

  choose.value = false
  clientSession.value = { id: clientSession.value?.id ?? uuid.value, first: false }
  session.set(BROWSER_SESSION_ID, clientSession.value)
}

function handleExistingBrick(brickData: RulesSet['bricks'][number], bricks: CanvasFilter['bricks']) {
  if (bricks.length <= 1) return

  const existingBrick = bricks.find((brick) => brick.name === brickData.name)
  if (existingBrick) {
    const index = bricks.indexOf(existingBrick)
    bricks.splice(index, 1)
  }
}

function handleNewBrick(brickData: RulesSet['bricks'][number], bricks: CanvasFilter['bricks']) {
  if (bricks.length >= 4) return

  const { index, name, folder, example } = brickData
  bricks.push({
    index,
    name,
    folder,
    percentage: percentageDistribution[bricks.length] ?? 0,
    example
  })
}

function handleBrickReplacement(brickData: RulesSet['bricks'][number], bricks: CanvasFilter['bricks']) {
  const { index, name, folder, example } = brickData
  bricks.splice(selected.value.index, 1, {
    index,
    name,
    folder,
    percentage: percentageDistribution[bricks.length] ?? 0,
    example
  })
}

function getScenario(existingBrick: CanvasFilter['bricks'][number] | undefined): string {
  if (clientSession.value?.first) return 'first-session'
  if (existingBrick) return 'brick-exists'
  return selected.value.index === -1 ? 'new-brick' : 'replace-brick'
}

export function useBricksFilter(values: ModelRef<Form<CanvasFilter>>, show: ModelRef<boolean>, input?: Ref<HTMLInputElement[]>) {
  const bricksMap = computed(() => new Map(values.value.form.value.bricks.map((brick) => [brick.name, brick])))

  const isBrickSelected = computed(() => (name: string) => (clientSession.value?.id ? bricksMap.value.has(name) : false))

  function selectOption(name: string, index: number = -1) {
    selected.value = {
      name,
      index: index === -1 ? values.value.form.value.bricks.length : index
    }
    show.value = true
  }

  function switchSelectedBrick(brickData: RulesSet['bricks'][number]) {
    const bricks = values.value.form.value.bricks
    const existingBrick = bricks.find((brick) => brick.name === brickData.name)

    const scenarioHandlers: Record<string, (brickData: RulesSet['bricks'][number], bricks: CanvasFilter['bricks']) => void> = {
      'first-session': handleFirstSession,
      'brick-exists': handleExistingBrick,
      'new-brick': handleNewBrick,
      'replace-brick': handleBrickReplacement
    }

    const scenario = getScenario(existingBrick)
    if (scenarioHandlers[scenario]) {
      scenarioHandlers[scenario](brickData, bricks)
    }

    if (scenario !== 'first-session') {
      resetSelection()
    }
  }

  const updatePercentages = debounce((e: Event) => {
    if (!input) return
    const target = e.target as HTMLInputElement
    const newPercentage = parseFloat(target.value)

    if (newPercentage < 100) {
      const remainingPercentage = 100 - newPercentage
      const elementsToUpdate = input!.value.length - 1
      const percentagePerElement = remainingPercentage / elementsToUpdate

      input!.value.forEach((elem, index) => {
        if (elem.classList.contains('focus')) return
        values.value.form.value.bricks[index].percentage = parseInt(percentagePerElement.toFixed(2))
      })
    }
  }, 300)

  function removeBrick({ name }: { name: string }) {
    const bricks = values.value.form.value.bricks
    const brick = bricksMap.value.get(name)

    if (brick && bricks.length > 1) {
      const index = bricks.indexOf(brick)
      bricks.splice(index, 1)
    }

    resetSelection()
  }

  onMounted(start)

  return {
    switchSelectedBrick,
    removeBrick,
    selectOption,
    updatePercentages,
    checked: isBrickSelected,
    matchMap: percentageDistribution
  }
}
