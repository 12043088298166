import type { App, DirectiveBinding } from 'vue'

export const outside = {
  install: (app: App) => {
    app.directive('outside', {
      beforeMount(el: HTMLElement & { clickOutsideEvent: (event: Event) => void }, binding: DirectiveBinding) {
        el.clickOutsideEvent = (event) => {
          const exclude = document.querySelector(`#${binding?.arg}`)
          if (!(el === event.target || el.contains(event.target as Node) || exclude?.contains(event.target as Node))) {
            binding.value(event)
          }
        }
        document.addEventListener('click', el.clickOutsideEvent, true)
      },
      unmounted(el: HTMLElement & { clickOutsideEvent: (event: Event) => void }) {
        document.removeEventListener('click', el.clickOutsideEvent, true)
      }
    })
  }
}
