<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{ name?: string }>()

const images = import.meta.glob<string>(`~/images/previews/*`, { eager: true, import: 'default' })

const image = computed(() => {
  const key = Object.keys(images).find((key) => key.includes(props.name || ''))
  return key ? images[key] : undefined
})
</script>

<template>
  <img :src="image" v-bind="$attrs" />
</template>
